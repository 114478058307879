// import moment from 'moment'
import { Events, TaskEventBus } from './EventBus'
import {
  sectionsStore,
  tasksStore,
  teamsStore,
} from '@/store'
import router from '@/router'

export default {
  components: {
    Deadline: () => import('@/components/UI/Deadline.vue'),
    IconAt: () => import('@/components/UI/icons/IconAt'),
    IconBullhorn: () => import('@/components/UI/icons/IconBullhorn'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis'),
    IconFire: () => import('@/components/UI/icons/IconFire'),
    IconThumbTack: () => import('@/components/UI/icons/IconThumbTack'),
    TaskImportanceChip: () => import('./TaskImportanceChip'),
    TaskMenu: () => import('@/components/MiddleColumn/FullTask/TaskMenu'),
  },

  computed: {
    hasExtraStatuses () {
      return tasksStore.getters.hasExtraStatuses
    },
    currentTeamId () {
      return teamsStore.state.currentTeamId
    },
    currentTeam () {
      return teamsStore.getters.currentTeam
    },
    cardColors () {
      return tasksStore.getters.cardColors(this.task.jid)
    },
    sectionText () {
      if (!this.task.section) return null
      const project = sectionsStore.getters.section(this.task.section, 'task')
      // if (!project) throw new Error(`Макс, где проект jid:${this.task.section} в задаче ${this.task.jid}?`)
      if (!project) return ''
      return project.name
    },
    task () {
      return tasksStore.state.data[this.jid] || this.taskObject || {}
    },
    wasNoticed () {
      return !!this.task.numUnreadNotices
    },
    hasUnread () {
      return !!this.task.numUnread
    },
    isNotificationsEnabled () {
      return this.taskObject.notificationsEnabled
    },
    isThisTaskOnScreen () {
      if (!this.task) return
      if (this.notHoverable) return
      const { currentChat } = this.$store.getters
      return currentChat === this.task.jid
    },
    isDone () {
      return this.task.taskStatus === 'done'
    },
    unreadCount () {
      const numUnread = this.task.numUnread ?? 0
      return numUnread > 99 ? '99+' : numUnread
    },
    doneCardStyle () {
      return this.isDone && { opacity: 0.5 }
    },
    hoveredCardStyle () {
      return this.hovered && { backgroundColor: this.cardColors.regular }
    },
    onScreenCardStyle () {
      return this.isThisTaskOnScreen && { borderLeftColor: 'var(--main-accent)' }
    },
    initialCardStyle () {
      return {
        backgroundColor: this.cardColors.light,
        borderLeftColor: '#EDF8FE',
      }
    },
    cardStyle () {
      return {
        ...this.initialCardStyle,
        ...this.hoveredCardStyle,
        ...this.doneCardStyle,
        ...this.onScreenCardStyle,
      }
    },
    badgeClassList () {
      return {
        'badge--unread': true,
        'badge--muted': !this.isNotificationsEnabled,
        'bg-main-accent': this.isNotificationsEnabled,
      }
    },
  },
  methods: {
    taskComplexityLabel (jid) {
      return tasksStore.getters.complexityLabel(jid)
    },
    taskStatusLabel (jid) {
      return tasksStore.getters.statusLabel(jid)
    },
    navTo (ev, link, task) {
      if (ev.ctrlKey || ev.shiftKey || ev.metaKey) {
        return
      }
      ev.preventDefault()
      const chatId = !this.isThisTaskOnScreen ? task.jid : null
      router.push({
        name: 'Chat',
        params: {
          jid: chatId,
          teamId: teamsStore.getters.currentTeam.uid,
        },
      })
    },
    searchBySection () {
      TaskEventBus.$emit(Events.SET_SECTION_FILTER, this.task.section)
    },
    searchByTag (tag) {
      TaskEventBus.$emit(Events.SET_TASK_SEARCH, tag)
    },
  },
}
